@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
   url("./fonts/Oxanium.ttf") format("truetype");
 }

 @font-face {
  font-family: 'Caveat';
  src: 
       
       url('./fonts/Caveat.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src: 
       
       url('./fonts/NunitoSans-Variable.ttf') format('truetype');
  font-weight: 200 1000;
  font-style: normal;
}
 

 body {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
/* Or target specific elements */
.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.font-oxanium {
  font-family: 'Oxanium', sans-serif;
}

.font-caveat {
  font-family: 'Caveat', cursive;
}

:root {
  /* Light Mode Colors */
  --color-primary-light: #143c6c; /* Versatile Calming Blue */
  --color-secondary-light: #d9a641; /* Soft Gold */
  --color-accent-light: #F7FAFC; /* Warm Off-White */
  --color-background-light: #e8e8e8; /* Light Gray */
}

.dark {
  /* Dark Mode Colors */
  --color-primary-dark: #07324f; /* L Blue */
  --color-secondary-dark: #d9a641; /* Brighter Gold */
  --color-accent-dark: #CBD5E0; /* Light Gray */
  --color-background-dark: #06263b; /* Dark Slate Blue */
}

.no-scroll {
  overflow: hidden;
}
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
